<template>
  <!-- 列表样式 -->
  <div class="chat-robot-box" style="display: block;">
    <div class="message-header"
        :class="{'border-bottom': !isChild}">
      <em class="icon-dot" style="float: left;top: 0.05rem;"></em>
      <div :style="examChoose ? 'font-weight: bold;':''" v-html="examChoose">1请选择您要咨询的考试名称</div>
    </div>
    <ul class="list lyt-2-columns">
      <template v-if="message.content.length">
        <li
          v-for="(item) in list"
          :key="item.id"
          @click="selectItem(item)"
        >
          {{item.name}}
        </li>
      </template>
      <template v-else>
        <li>无类型</li>
      </template>
    </ul>
    <!-- 查看更多按钮 -->
    <div
      class="view-more"
      :class="{ open: allDisplayed }"
      v-if="hasMore"
      @click="clickDisplayed"
    >{{allDisplayed ? '收起' : '其他类别咨询'}}</div>
  </div>
</template>

<script>
import { buildData } from '@/components/Bubbles/interface'
import { mapState } from 'vuex'

export default {
  name: 'BubbleList',
  props: {
    message: Object,
    isChild: Boolean
  },
  data () {
    return {
      defaultCount: 6,
      allDisplayed: false
    }
  },
  computed: {
    ...mapState(['examChoose', 'examId', 'examName', 'level']),
    hasMore () {
      return this.message.content.length > this.defaultCount
    },
    list () {
      if (this.allDisplayed) {
        return this.message.content
      } else {
        return this.message.content.slice(0, this.defaultCount)
      }
    }
  },
  methods: {
    clickDisplayed () {
      console.log('折叠')
      this.allDisplayed = !this.allDisplayed
      this.$store.commit('updateFreshNeed', true)
    },
    selectItem (item) {
      // 存储 sceneId
      this.$store.commit('updateSceneId', item.sceneId)
      // 提问并且切换 场景范围
      this.$store.dispatch('askQuestion', {
        from: 2,
        question: item.name,
        examId: item.id,
        examName: item.name,
        dataType: 'exam-selected',
        onlyRobot: true
      })
      // 更新场景
      item.level = 2
      this.$store.commit('updateExam', item)
      this.$store.commit('updateExamName', item.name)
      this.$store.commit('updateExamId', item.id)
    }
  },
  mounted () {
    if (this.$store.state.navigation) {
      const item = {
        name: this.$store.state.navigation.name,
        level: 1
      }
      this.$store.commit('updateExam', item)
    }
    // 语音播报
    if (this.$store.state.examChooseVoice) {
      const it = {
        type: 9999,
        data: this.$store.state.examChooseVoice
      }
      this.$store.commit('appendBubble', buildData(it))
    }
  }
}
</script>
